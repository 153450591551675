<template>
  <v-container>
  <v-row>
    <v-col cols="12" class="d-none d-md-block">
    <div class="top-toolbar">
      <div class="navigate-btn" >
      <v-btn icon disabled>
        <v-icon>fas fa-angle-left</v-icon>
      </v-btn>
      </div>
      <div class="page-title">
      <span>{{title}}</span>
      <img src="@/assets/layout/logo.png" alt="">
      </div>
      <div class="second-btn"></div>
    </div>
    </v-col>
  </v-row>
  <v-row>
    
    <v-col cols="12" xl="4" md="6" lg="6" v-if="STATIC.bonusRoublesEnable">
      <v-card color="grey lighten-4" light link to="/information?tab=bonuses" class="home-navigate-card" elevation="0">
        <div class="d-flex flex-no-wrap justify-space-between">
<!--          <div style="position: absolute; width: 100%; height: 100%; margin-top: 50%; transform: translateY(-80%); padding-left: 10%; opacity: 0.3; z-index: 2;">
            <i class="fas fa-wallet card-icon grey&#45;&#45;text text&#45;&#45;darken-2"></i>
          </div>-->
          <div style="z-index: 3">
            <v-card-title class="no-wrap-title pr-0 grey--text text--darken-2">Мои бонусы </v-card-title>
            <v-card-subtitle class="pr-0 pb-0">
              <h1 class="primary--text font-weight-black ">
                <template v-if="bonusLoading">
                  <v-skeleton-loader type="chip"></v-skeleton-loader>
                </template>
                <template v-else>
                  <span>{{bonusBalancePrint}}<span class="ml-1" style="font-size: 60%">₽</span></span>
                </template>
              </h1>
              <template v-if="bonusLoading">
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </template>
              <template v-else>
                <div class="orange--text text--darken-2" v-if="bonusExpire && bonusExpire.balance">
                  <i class="fas fa-fire-alt"></i>&nbsp;
                  <span class="font-weight-black">{{ bonusExpire.balance }}<span style="font-size: 90%">₽</span></span><span class="grey--text text--darken-1 ">&nbsp;сгорит {{ bonusExpireDate }}</span>
                </div>
                <div v-else>
                  <span class="grey--text text--darken-1 ">Доступно для списания</span>
                </div>
              </template>
            </v-card-subtitle>
          </div>
          <v-avatar class="ma-3 mx-0" size="125" tile>
            <i class="fas fa-wallet card-icon grey--text text--lighten-2 "></i>
          </v-avatar>
        </div>
      </v-card>
    </v-col>
    
    <v-col cols="12" xl="4" md="6" lg="6" >
    <v-card color="primary lighten-1" dark link to="/appointments" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Мои записи к врачу</v-card-title>
        <v-card-subtitle class="pr-0">Просмотр и редактирование ваших записей</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="far fa-calendar-check card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6" >
    <v-card color="primary darken-1" dark link to="/appointment" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Записаться на прием</v-card-title>
        <v-card-subtitle class="pr-0">Выбор врача и времени посещения клиники</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="far fa-calendar-plus card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>
      
    <v-col cols="12" xl="4" md="6" lg="6" >
    <v-card color="primary" dark link to="/history" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">История посещений</v-card-title>
        <v-card-subtitle class="pr-0">Информация о визитах в медицинский центр</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="fas fa-clipboard-list card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6" >
    <v-card color="primary darken-2" dark link to="/analyses" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Результаты исследований</v-card-title>
        <v-card-subtitle class="pr-0">Сведения о результатах лабораторных исследований</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="fas fa-flask card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6" v-if="STATIC.appUseTelemedicine">
    <v-card color="primary" dark link to="/chat" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Телемедицина</v-card-title>
        <v-card-subtitle class="pr-0">Консультации с врачами онлайн</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="far fa-comment-dots card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6">
    <v-card color="primary lighten-1" dark link to="/information" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Профиль</v-card-title>
        <v-card-subtitle class="pr-0">Просмотр ваших данных и добавление пациентов</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="fas fa-user-circle card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6" v-if="STATIC.appClinicUrl" flat>
    <v-card color="primary darken-1" dark link :href="STATIC.appClinicUrl" target="_blank" class="home-navigate-card">
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Медицинский центр</v-card-title>
        <v-card-subtitle class="pr-0">Перейти на сайт медицинского центра</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="fas fa-globe card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6">
    <v-card color="primary darken" dark link to="/contact" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Контакты</v-card-title>
        <v-card-subtitle class="pr-0">Адрес и телефон регистратуры</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="fas fa-hospital card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>

    <v-col cols="12" xl="4" md="6" lg="6">
    <v-card color="primary darken-1" dark link to="/map" class="home-navigate-card" flat>
      <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="no-wrap-title pr-0">Как проехать</v-card-title>
        <v-card-subtitle class="pr-0">Схема проезда в медицинский центр</v-card-subtitle>
      </div>
      <v-avatar class="ma-3 mx-0" size="125" tile>
        <i class="fas fa-map-marked-alt card-icon"></i>
      </v-avatar>
      </div>
    </v-card>
    </v-col>
    
  

    <!-- <v-col cols="12" xl="4" md="6" lg="6" >
        <v-card color="primary darken-1" dark link to="/appointment-old" class="home-navigate-card">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="no-wrap-title pr-0">Записаться на прием NEW</v-card-title>
              <v-card-subtitle class="pr-0">Выбор врача и времени посещения клиники</v-card-subtitle>
            </div>
            <v-avatar class="ma-3 mx-0" size="125" tile>
              <i class="far fa-calendar-plus card-icon"></i>
            </v-avatar>
          </div>
        </v-card>
      </v-col> -->

      
  <v-dialog persistent v-model="createLinkAppointmentDialog" max-width="400">
    <v-card>
    <v-card-title>Создание записи</v-card-title>
    <v-card-text class="">
      <div v-if="this.loadingCreateAppointment" class="text-center mt-5">
      <v-progress-circular
        :size="50"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
      </div>
      <p v-if="this.createLinkAppointmentErrorText">Не удалось создать запись. {{this.createLinkAppointmentErrorText}}</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text v-if="!this.loadingCreateAppointment" @click="closeCreateDialog" rounded color="primary" dark>Закрыть</v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-if="this.canOpenRateModal(this.rateDialogOpenLoading, this.rateDialogData)" persistent v-model="rateAppointmentDialog" max-width="600">
    <v-card class="overflow-hidden">
    <v-card-title>Оцените прошлое посещение</v-card-title>
    <v-card-text class="mt-2">
      <div>
      <div>
        <v-icon class="mr-1" x-small style="margin-top: -2px">far fa-calendar-alt</v-icon>
        <span class="">{{ dateFormat(this.rateDialogData.date, this.rateDialogData.isAdditional) }}</span>
      </div>
      <div v-bind:class="{'d-none': !this.rateDialogData.customer}">
        <span><b>Пациент:</b> {{ (this.rateDialogData.customer) ? this.rateDialogData.customer.trim() : '' }}</span>
      </div>
      <div v-bind:class="{'d-none': !this.rateDialogData.user}">
        <span><b>Специалист:</b> {{ (this.rateDialogData.user) ? this.rateDialogData.user.trim() : '' }}</span>
      </div>
      <div v-bind:class="{'d-none': !this.rateDialogData.service}">
        <span><b>Услуга:</b> {{ (this.rateDialogData.service) ? this.rateDialogData.service.trim() : '' }}</span>
      </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <div class="w-100 text-center">
      <v-btn text v-on:click="laterRateDialog">Напомнить позже</v-btn>
      <v-btn text v-on:click="closeRateDialog">Больше не показывать</v-btn>
      <v-btn text v-on:click="openRateDialog(rateDialogData.reviewToken, rateDialogData.reviewTokenId)" color="primary" dark>Оценить</v-btn>
      </div>
    </v-card-actions>
    </v-card>
  </v-dialog>
  </v-row>
  
    <v-dialog :value="showAppointmentByGetParametersDialog" max-width="400" @click:outside="cancelNewAppointment">
      <v-card>
        <v-card-title>Создание записи</v-card-title>
        <v-card-text>
          <v-card flat class="d-flex flex-wrap" style="gap: 0.25rem">
            <div style="min-width: 15%">Врач:&nbsp;</div>
            <div class="grow" style="word-break: break-word; max-width: calc(85% - 0.25rem)">
              <v-skeleton-loader
                type="text"
                class="py-1"
                v-if="newAppointmentUserLoading || !newAppointmentDoctorFio"
              ></v-skeleton-loader>
              <span v-else>
                {{newAppointmentDoctorFio}}
              </span>
            </div>
          </v-card>
          <v-form ref="newAppointmentForm">
            <v-select
              :items="customersForSelect"
              label="Кто пойдет на прием?"
              no-data-text="Не удалось загрузить клиентов"
              v-model="newAppointmentCustomer"
              :rules="[
                val => !!val || 'Укажите кто пойдет на прием'
              ]"
            >
              <template v-slot:append-outer>
                <v-btn icon @click="toggleAddCustomerModal">
                  <v-icon small>fas fa-user-plus</v-icon>
                </v-btn>
              </template>
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn color="primary" text @click="createAppointmentFromGetParams">
            Создать
          </v-btn>
          <v-btn text @click="cancelNewAppointment()" >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
      <CreateRepresentativeDialog v-model="addNewCustomer" />
    </v-dialog>
    <v-snackbar
      v-model="newAppointmentSnackbar"
      text
      outlined
      color="success"
      flat
      timeout="20000"
    >
        <v-icon small color="success" left>fas fa-check</v-icon>
        Запись успешно создана
      
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            small
            dense
            v-bind="attrs"
            color="success"
            @click="$router.push('/appointments')"
          >
            Перейти
          </v-btn>
          <v-btn
            text
            small
            dense
            v-bind="attrs"
            color="success darken-1"
            @click="newAppointmentSnackbar = false"
          >
            <v-icon x-small>fas fa-times</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import store from '@/store'
import router from '@/router';
import CreateRepresentativeDialog from "@/components/CreateRepresentativeDialog";

export default {
  mounted() {
    this.$store.dispatch('setCurrentCouponService', null);
    // Если переданы параметры создания записи по ссылке
    const appointmentDate = JSON.parse(localStorage.getItem('newAppointmentDate'));
    const appointmentIdUser = JSON.parse(localStorage.getItem('newAppointmentIdUser'));
    if (appointmentDate && appointmentIdUser) {
      this.showAppointmentByGetParametersDialog = true;
      this.getDoctor(appointmentIdUser);
    }

    // Запрос на получение записи для оценки
    this.$http.post(window.config.apiUrl + '/appointment/get-last-review', {
      idUser: this.getUser.id
    }).then(response => {
      this.rateDialogOpenLoading = false;
      this.rateDialogData = response.data;
    });
    
    this.bonusLoading = true;
    this.$http.post(window.config.apiUrl + '/customer-bonuses/get-balance-card').then(({data}) => {
      if (data.status == 'success') {
        this.bonusLoading = false;
        this.bonusBalance = data.balance;
        this.bonusExpire = data.expire;
      }
    }).catch(error => console.log(error));
  },
  computed: {
    ...mapGetters([
      'getUser',
      'STATIC'
    ]),
    
    // Выводит дату сгорания бонусов
    bonusExpireDate() {
      let date = this.$moment(this.bonusExpire.date);
      if (!date.isValid()) {
        return 'совсем скоро';
      }
      return date.format('D MMMM YYYY');
    },
    
    // Выводит форматированный бонусный баланс
    bonusBalancePrint() {
      return this.bonusBalance ?? '---';
    },
    
    customersForSelect() {
      const user = this.getUser;
      let users = [];
      users.push({
        text: this.getFio(user.surname, user.name, user.middleName),
        value: user.id
      });
      for (let representative of user.representatives) {
        users.push({
          text: this.getFio(representative.surname, representative.name, representative.middleName),
          value: representative.id
        });
      }
      return users;
    },
    
    newAppointmentDoctorFio() {
      if (!this.newAppointmentDoctor) {
        return null;
      }
      const fio = this.getFio(this.newAppointmentDoctor.surname, this.newAppointmentDoctor.name, this.newAppointmentDoctor.middleName);
      return fio;
    }
  },
  methods: {
    // Возвращает строковое представление имени
    getFio(surname, name, middleName) {
      return (surname ? `${surname} ` : ``) + (name ? `${name} ` : ``) + (middleName ? middleName : ``);
    },
    
    // Показать/скрыть диалоговое окно с созданием записи
    toggleAddCustomerModal() {
      this.addNewCustomer = !this.addNewCustomer;
    },
    
    // Получить данные врача
    getDoctor(idUser) {
      this.newAppointmentUserLoading = true;
      this.$http.get(window.config.apiUrl + `/widgets/get-users?idUser=${idUser}`)
        .then(({data}) => {
          if (data && data.users && data.users[idUser] && data.users[idUser].id == idUser) {
            this.newAppointmentDoctor = data.users[idUser];
          }
          // alert error
        } )
        .catch( error => console.log(error) )
        .finally( () => this.newAppointmentUserLoading = false );
    },
    
    // Отмена новой записи через сайт
    cancelNewAppointment() {
      localStorage.removeItem('newAppointmentDate');
      localStorage.removeItem('newAppointmentIdUser');
      localStorage.removeItem('newAppointmentIdService');
      this.showAppointmentByGetParametersDialog = false;
    },
    
    // Создание записи по ссылке через гет параметры
    createAppointmentFromGetParams() {
      // Валидация
      if (!this.$refs.newAppointmentForm.validate()) {
        return;
      }
      
      this.createLinkAppointmentDialog = true;
      this.loadingCreateAppointment = true;

      
      let services = null;
      let storageServices = JSON.parse(localStorage.getItem('newAppointmentIdService'));
      if (storageServices) {
        services = storageServices?.split(';');
        console.log('1', services);
      }
      if (services && services.length === 1 && services[0] == '') {
        services = null;
        console.log('2', services);
      }
      let url = '/appointment/create-appointment';
      let createPayment = false;
      const customerSource = localStorage.getItem('customerSource');
      let options = {
        idUser: JSON.parse(localStorage.getItem('newAppointmentIdUser')),
        idCustomer: this.newAppointmentCustomer,
        dateTime: JSON.parse(localStorage.getItem('newAppointmentDate')),
        token: this.$store.getters.token,
        source: customerSource || null
      }
      console.log('3', services);
      if (services && services.length) { // До понедельника
        url = '/payments/create-payment';
        delete(options.dateTime);
        options.dateFrom = JSON.parse(localStorage.getItem('newAppointmentDate'));
        options.idPayer = this.$store.getters.getUser.id;
        options.services = services;
        createPayment = true;
      }

      this.$http.post(window.config.apiUrl + url, options).then((response) => {
        if ((!createPayment && response.data.status == 'success') || (response.data?.result?.status == 'success')){
          //this.$router.push('/appointments');
          this.loadingCreateAppointment = false;
          this.createLinkAppointmentDialog = false;
          this.newAppointmentSnackbar = true;
          localStorage.removeItem('customerSource');
        } else {
          this.loadingCreateAppointment = false;
          if (response.data.errorText){
            this.createLinkAppointmentErrorText = response.data.errorText
          } else {
            this.createLinkAppointmentErrorText = 'Ошибка создании записи'
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.loadingCreateAppointment = false;
        this.createLinkAppointmentErrorText = true;
      }).finally( () => {
        // Удаляем параметры
        localStorage.removeItem('newAppointmentDate');
        localStorage.removeItem('newAppointmentIdUser');
        localStorage.removeItem('newAppointmentIdService');
        this.showAppointmentByGetParametersDialog = false
      } );
    },
    
    // Проверяем можем ли мы открыть модалку с предложением оценить последний прием
    canOpenRateModal(isLoading, data) {
    let closedForever = window.localStorage.getItem('rateNeverAsk');
    let lastCloseTime = window.localStorage.getItem('lastRateAskTime');
    let now = this.$moment().format('X') * 1;
    // Проверяем что последний раз модалка была открыта менее 12 часов назад
    if (closedForever) {
      return false;
    }
    if (!lastCloseTime || (lastCloseTime * 1) + 12 * 3600 < now) {
      return (!isLoading && data);
    } else {
      return false;
    }
    },
    // Форматирует дату
    dateFormat(date, isAdditional = false) {
    if (date) {
      if (isAdditional) {
        return this.$moment(date).format("LL, dddd");
      }
      return this.$moment(date).format("LL, dddd, LT");
    } else {
      return '';
    }
    },
    // Закрыть диалог создания записи по ссылке
    closeCreateDialog(){
    this.createLinkAppointmentDialog = false;
    },
    closeRateDialog() {
    this.rateAppointmentDialog = false;
    window.localStorage.setItem('rateNeverAsk', true);
    },
    laterRateDialog() {
    this.rateAppointmentDialog = false;
    let now = this.$moment().format('X')
    window.localStorage.setItem('lastRateAskTime', now);
    },
    openRateDialog(token, id) {
    console.log(123123);
    router.push(`/get-review?idReview=${id}&token=${token}&from=appointments`)
    }
  },
  data () {
    return {
      title: 'Начальная страница',
      createLinkAppointmentDialog: false, // Диалог создания записи по ссылке
      rateAppointmentDialog: true,
      rateDialogOpenLoading: true,
      loadingCreateAppointment: false,
      createLinkAppointmentErrorText: '',
      rateDialogData: null,
      // Создание записи по get запросу
      queryAppointment: {
        date: null,
        idUser: null
      },
      showAppointmentByGetParametersDialog: false,
      addNewCustomer: false,
      newAppointmentUserLoading: false,
      newAppointmentDoctor: null,
      newAppointmentCustomer: null,
      newAppointmentSnackbar: false,
      bonusBalance: null,
      bonusLoading: false,
      bonusExpire: null
    }
  },
  components: {
    CreateRepresentativeDialog
  }
}

</script>

<style scoped>

.card-icon {
  font-size: 80px;
}

.home-navigate-card {
  transition: transform 0.2s, box-shadow 0.2s;
}

.home-navigate-card:hover{
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0 2px 2px 0 !important;
  transform: translate(0, -2px);
}

.no-wrap-title{
  word-break: break-word;
  line-height: 1.2;
  margin-bottom: 4px;
}

.page-title img{
  height: 40px;
  display: none;
}

</style>

<style>
.v-text-field__details {
  min-height: max-content !important;
  transition: height 0.4s !important;
}

.v-messages {
  min-height: max-content !important;
  height: max-content !important;
  transition: height 0.4s !important;
}
</style>